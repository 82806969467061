<template>
  <div class="wrapper">
    <div  class="hideText" ref="hideTextRef" id="hideTextId">{{text}}</div>
    <input id="exp1" class="exp" type="checkbox" />
    <div class="text">
      <label v-show="clientHeight > 48" class="btn" for="exp1"></label>
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 文本
    text: {
			type: String,
			default: ''
    },
  },
  // 数据对象
  data() {
    return {
      clientHeight:0
    };
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(()=>{
      this.clientHeight = this.$refs.hideTextRef.clientHeight
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}
.text {
  font-family: PingFangMedium;
  color: #666666;
  line-height: 24px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;  //将对象作为弹性伸缩盒子模型显示
  -webkit-line-clamp: 2; //下多行文字溢出点点点... 
  -webkit-box-orient: vertical; //设置或检索伸缩盒对象的子元素的排列方式
  position: relative;
}
.hideText{
  position: absolute;
  z-index: -10;
  opacity: 0;
  line-height: 24px;
  font-size: 16px;
}
.text::before {
  content: "";
  height: calc(100% - 24px);
  float: right;
}
.btn {
  float: right;
  clear: both;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 400;
  color: #15B7DD;
  line-height: 25px;
  cursor: pointer;
}
.exp {
  display: none;
}
.exp:checked + .text {
  -webkit-line-clamp: 999;
}
.btn::before {
  content: "展开";
}
.exp:checked + .text .btn::before {
  content: "收起";
}
</style>
