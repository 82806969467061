<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        shopDetail.packageName
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="shopimg">
        <img class="img" :src="imgSrc" alt="" />
        <!-- 套餐 -->
        <div
          class="imgList"
          v-if="
            $route.query.packageId &&
            shopDetail.pcDetailPicture &&
            shopDetail.pcDetailPicture.length > 1
          "
        >
          <img
            :class="{ active: imgSrc == item }"
            :src="item"
            v-for="(item, index) in shopDetail.pcDetailPicture"
            @click="imgSrc = item"
            :key="index"
            alt=""
          />
        </div>
      </div>
      <div class="shopDetail">
        <p class="name" v-if="$route.query.packageId">
          <span>套餐</span>{{ shopDetail.packageName }}
        </p>
        <div
          class="shopMessage"
          :style="{ backgroundImage: 'url(' + imgSrc1 + ')' }"
        >
          <div class="price">
            <p class="title">价格：</p>
            <!-- 套餐 -->
            <p 
              class="new" 
              :class="{'newFree': !shopDetail.packagePrice}"
              v-if="$route.query.packageId">
              ￥<span>{{ managePrice(shopDetail.packagePrice * 1)[0] }}<span class="new_point">.{{ managePrice(shopDetail.packagePrice * 1)[1] }}</span></span>
            </p>
            <p class="old" v-if="$route.query.packageId">
              ￥{{ (shopDetail.originalPrice * 1).toFixed(2) }}
            </p>
          </div>
          <div class="navs" v-if="couponList && couponList.length && shopDetail.packagePrice">
            <p class="title">优惠券：</p>
            <p
              class="nav"
              v-for="(ite, ind) in couponList"
              :key="ind"
              v-show="ind < 4"
            >
              {{ite.type == 1?('满'+ite.fullPrice+'减'+ite.couponPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')+ite.discount+'折'):''}}
            </p>
            <p
              class="button"
              @click.stop="couponFlag = !couponFlag"
            >
              <!-- @click="
                (visible = true),
                  (fontText = '系统升级，请下载华夏云课堂app领取')
              " -->
              领券<a-icon class="icon" type="down" v-if="!couponFlag" /><a-icon class="icon" v-else type="up" />
            </p>
            <div class="couponList" v-show="couponFlag">
              <div class="coupon" :class="{geted:ite.noReceive}" v-for="(ite, ind) in couponList" :key="ind">
                <div class="couponLeft">
                  <!-- 满减券 -->
                  <p class="price" v-if="ite.type == 1">￥<span
                    :style="[
                      {
                        fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                      },
                      {
                        lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                      }
                    ]
                    ">{{ite.couponPrice}}</span></p>
                  <!-- 折扣券 -->
                  <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                  <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                </div>
                <div class="couponRight">
                  <p class="couponName">{{ite.name}}</p>
                  <p class="useTime">{{useTime(ite)}}</p>
                  <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                </div>
                <div v-if="!ite.noReceive" @click="receiveCoupon(ite)" class="couponBtn">领取</div>
                <div v-else class="couponStyle">已领取</div>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="$route.query.packageId"
          class="introduce"
        >
        <TextOverflow :text="shopDetail.introduction" />
        <!-- {{shopDetail.introduction}} -->
          <!-- {{isUnfold?shopDetail.introduction:manageInfo(shopDetail.introduction)}}
          <span class="changeUnfold" v-if="shopDetail.introduction.length > 94" @click="changeUnfold">{{isUnfold?'收起':'展开'}}
            <a-icon type="down" v-if="!isUnfold" /><a-icon
                type="up"
                v-if="isUnfold"
              /></span> -->
        </p>
        <div
          class="classDate"
          v-if="courseList && courseList.filter(item=>{return item.haveClass}).length"
        >
          <p class="title">包含课程：</p>
          <div class="courseList">
            <div 
              class="courseItem" 
              :class="{'courseItemShadow': (showClassIndex == ind && !!ite.hidden)}"
              v-for="(ite, ind) in courseList.filter(item=>{return item.haveClass})" 
              :key="ind">
              <img :src="ite.pcPhoto" alt="" />
              <div class="courseMessage">
                <p class="name onlyOneHidden">{{ ite.productName }}</p>
                <p
                  class="findClassDate"
                  v-if="ite.haveClass"
                  @click="getCourseDateList(ite, ind)"
                >
                  {{ ite.openTimeName ? ite.openTimeName : "选择班级" }}
                  <a-icon type="down" v-if="!ite.hidden" /><a-icon
                    type="up"
                    v-if="ite.hidden"
                  />
                </p>
              </div>
              <div
                class="courseDateListWrap"
                :class="ind % 2 ? 'courseDateListWrapR' : 'courseDateListWrapL'"
                v-if="ite.hidden"
                :style="{ 
                  left: ind % 2 ? 'auto' : '-1px', 
                  right: ind % 2 ? '-1px' : 'auto',
                }"
              >
                <div 
                  class="courseDateList"
                  :style="{
                    width: ite.courseDateList.length > 3 ? '691px' : 'auto',
                    flexWrap: ite.courseDateList.length > 3 ? 'wrap' : '',
                    justifyContent: ite.courseDateList.length == 1 ? 'center' : 'flex-start',
                  }">
                  <div
                    class="courseDateItem"
                    :class="{ active: ite.openTimeId === i.openTimeId }"
                    v-for="(i, j) in ite.courseDateList"
                    :key="j"
                    v-show="mallClass || j < 3"
                    @click="selectOpenTimeId(ite,i)"
                  >
                    <div class="top">
                      <p class="name onlyOneHidden">{{ i.className }}</p>
                      <p class="num">
                        剩余：<span>{{ i.currentNumber }}</span>
                      </p>
                    </div>
                    <p class="startTime">{{ i.startTime + '—' + i.endTime }}</p>
                  </div>
                </div>
                <p
                  class="showAll"
                  v-if="ite.courseDateList.length > 3"
                >
                  <span @click="mallClass = !mallClass"
                    >{{ mallClass ? "收起" : "更多期数"
                    }}<a-icon
                      class="icon"
                      :style="{
                        transform: mallClass ? 'rotate(-90deg)' : 'rotate(90deg)',
                      }"
                      type="double-right"
                  /></span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="classDate" v-if="productList.length && false" style="margin-top:8px;">
          <p class="title">相关商品：</p>
          <div class="classList">
            <a-carousel arrows :infinite="false" :dots="false" v-if="clientWidth>=16000">
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="top: 10px; left: 0;z-index:1;"
                v-show="productList.length > 2"
              >
                <a-icon type="left" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="top: 10px; right: 0"
                v-show="productList.length > 2"
              >
                <a-icon type="right" />
              </div>
              <div
                class="carouselList"
                v-for="(item, index) in Math.ceil(productList.length / 2)"
                :key="index"
              >
                <div
                  class="item"
                  v-for="(ite, ind) in productList.slice(
                    index * 2,
                    index * 2 + 2
                  )"
                  :key="ind"
                >
                  <img class="img" :src="ite.picture" alt="" />
                  <img
                    class="checked"
                    v-if="ite.checked"
                    @click="$set(ite, 'checked', false)"
                    src="@/assets/image/knowledgeMall/checked.png"
                    alt=""
                  />
                  <img
                    class="checked"
                    v-if="!ite.checked"
                    @click="$set(ite, 'checked', true)"
                    src="@/assets/image/knowledgeMall/unChecked.png"
                    alt=""
                  />
                  <div class="right">
                    <p class="produceName onlyOneHidden">{{ ite.name }}</p>
                    <div class="bottom">
                      <p class="price">
                        <span v-if="ite.price" style="font-size:12px;">￥</span>{{ ite.price ? ite.price.toFixed(2) : "免费" }}
                      </p>
                      <div class="count">
                        <a-icon
                          class="icon"
                          type="minus"
                          @click="changeCount(ite, -1)"
                        />
                        <a-input-number
                          id="inputNumber"
                          v-model="ite.count"
                          :min="1"
                        />
                        <a-icon
                          class="icon"
                          type="plus"
                          @click="changeCount(ite, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-carousel>
            <div
              v-show="clientWidth<16000 && productList.length > 1"
              slot="prevArrow"
              class="custom-slick-arrow swiper-product-next"
              style="top: 10px; left: 0; z-index: 1"
            >
              <a-icon type="left" />
            </div>
            <swiper class="carouselList" :style="{width:productList.length > 1?'502px!important':'580px!important'}" ref="mySwiper" :options="productSwiperOptions" v-if="clientWidth<16000">
              <swiper-slide v-for="(ite, ind) in productList" :key="ind">
                <div class="item" :class="{noprev:productList.length == 1}">
                  <img class="img" :src="ite.picture" alt="" />
                  <img
                    class="checked"
                    v-if="ite.checked"
                    @click="$set(ite, 'checked', false)"
                    src="@/assets/image/knowledgeMall/checked.png"
                    alt=""
                  />
                  <img
                    class="checked"
                    v-if="!ite.checked"
                    @click="$set(ite, 'checked', true)"
                    src="@/assets/image/knowledgeMall/unChecked.png"
                    alt=""
                  />
                  <div class="right">
                    <p class="produceName onlyOneHidden">{{ ite.name }}</p>
                    <div class="bottom">
                      <p class="price">
                        <span v-if="ite.price" style="font-size:12px;">￥</span>{{ ite.price ? ite.price.toFixed(2) : "免费" }}
                      </p>
                      <div class="count">
                        <a-icon
                          class="icon"
                          type="minus"
                          @click="changeCount(ite, -1)"
                        />
                        <a-input-number
                          id="inputNumber"
                          v-model="ite.count"
                          :min="1"
                        />
                        <a-icon
                          class="icon"
                          type="plus"
                          @click="changeCount(ite, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              v-show="clientWidth<16000 && productList.length > 1"
              slot="nextArrow"
              class="custom-slick-arrow swiper-product-prev"
              style="top: 10px; right: 0"
            >
              <a-icon type="right" />
            </div>
          </div>
        </div>
        <div
          class="buybutton"
          @click="isCanBuy"
        >
          <a-icon type="shopping" />
          <p>立即购买</p>
        </div>
      </div>
    </div>
    <!-- 套餐内容 -->
    <p class="moduletitle" v-if="setmealList.length"><span></span>套餐内容</p>
    <div class="setmealList" v-if="setmealList.length">
      <!-- 两个 -->
      <div class="setmealshopList" v-if="setmealList.length<=4">
        <div
          class="industryitem" :class="setmealList.length==2?'industryitems':''"
          v-for="(item, index) in setmealList"
          :key="index"
          @click="routerPush(item)"
        >
          <div class="itemcontent">
            <img :src="item.pcPhoto" alt="" />
            <div class="setmealcontent">
              <p class="name onlyOneHidden">{{ item.productName }}</p>
              <div class="nav">
                <p class="label" v-if="item.type == 1">
                  {{ item.courseCategoryName }} | 视频数量：{{ item.classHour }}个
                </p>
                <!-- <p class="oldPrice">{{item.oldPrice}}</p>
                    <p class="navs" v-for="(ite,ind) in item.discount.split(',')" :key="ind">{{ite}}</p> -->
              </div>
              <p class="newPrice">
                ￥<span>{{ managePrice(item.price * 1)[0] }}<span class="new_point">.{{ managePrice(item.price * 1)[1] }}</span></span>
              </p>
            </div>
          </div>
          <div class="connector" v-if="index + 1 != setmealList.length">
            <img
              style="width: 24px; height: 24px"
              class="icon"
              type="plus"
              src="@/assets/image/20220607-143754.png"
              alt=""
            />
          </div>
        </div>
        <div class="industryitem" :class="setmealList.length==2?'industryitems':''" v-if="setmealList.length == 2">
          <img
            class="rightArrow"
            src="@/assets/image/knowledgeMall/rightArrow.png"
            alt=""
          />
          <div class="buyBox">
            <p class="title">套餐价:</p>
            <div class="price">
              <p class="new">
                ￥<span>{{ managePrice(shopDetail.packagePrice * 1)[0] }}<span class="new_point">.{{ managePrice(shopDetail.packagePrice * 1)[1] }}</span></span>
              </p>
              <p class="old">
                原价: ￥{{ shopDetail.originalPrice.toFixed(2) }}
              </p>
            </div>
            <!-- <p class="warning">*此价格不包含已购买过的商品</p> -->
          </div>
        </div>
      </div>
      <img
        v-if="setmealList.length == 3"
        class="steps"
        src="@/assets/image/knowledgeMall/setmealBack.png"
        alt=""
      />
      <img
        v-if="setmealList.length == 4"
        class="stepsfour"
        src="@/assets/image/knowledgeMall/setmealBackfour.png"
        alt=""
      />
      <div v-if="setmealList.length != 2 && setmealList.length <= 4" class="shopMessage">
        <p class="title">套餐价:</p>
        <div class="price">
          <p class="new">
            ￥<span>{{ managePrice(shopDetail.packagePrice * 1)[0] }}<span class="new_point">.{{ managePrice(shopDetail.packagePrice * 1)[1] }}</span></span>
          </p>
          <p class="old">原价: ￥{{ shopDetail.originalPrice.toFixed(2) }}</p>
        </div>
        <p class="warning">*此价格不包含已购买过的商品</p>
      </div>
      <!-- 两个以上 -->
      <!-- banner轮播 -->
      <div
        class="swiper"
        v-if="
          setmealList &&
          setmealList.length > 4
        "
      >
        <template>
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              v-for="(item, index) in setmealList"
              :key="index"
            >
              <div class="industryitem">
                <div class="itemcontent">
                  <img :src="item.picture" alt="" @click="routerPush(item)" />
                  <div class="content" @click="routerPush(item)">
                    <p class="name onlyOneHidden">{{ item.productName }}</p>
                    <div class="nav">
                      <p class="label" v-if="item.type == 1">
                        {{ item.courseCategoryName }} |
                        视频数量：{{ item.classHour }}个
                      </p>
                      <p class="label" v-if="item.type == 4">
                        {{
                          item.isExam == 1
                            ? item.examNum + "次考试次数"
                            : "无限次"
                        }}
                      </p>
                      <!-- <p class="navs" v-for="(ite,ind) in item.discount.split(',')" :key="ind">{{ite}}</p> -->
                    </div>
                    <p class="newPrice">
                      ￥<span>{{ managePrice(item.price * 1)[0] }}<span class="new_point">.{{ managePrice(item.price * 1)[1] }}</span></span>
                    </p>
                  </div>
                </div>
                <div
                  class="connector"
                  v-if="
                    index + 1 !=
                    setmealList.length
                  "
                >
                  <a-icon type="plus" class="icon" />
                </div>
              </div>
            </swiper-slide>
            <!-- 前进后退按钮 -->
            <div
              v-show="
                setmealList.length != 3
              "
              class="swiper-button-prev swiper_btn swiper-button-white"
              slot="button-prev"
              @mouseover="changeMask(1)"
              @mouseout="changeMask(2)"
            >
              <img
                v-show="!swiperLeft"
                src="@/assets/image/knowledgeMall/bannerLeft.png"
                alt=""
              />
              <img
                v-show="swiperLeft"
                src="@/assets/image/knowledgeMall/bannerLeft2.png"
                alt=""
              />
            </div>
            <div
              v-show="
                setmealList.length != 3
              "
              class="swiper-button-next swiper_btn swiper-button-white"
              slot="button-next"
              @mouseover="changeMask(3)"
              @mouseout="changeMask(4)"
            >
              <img
                v-show="swiperRight && rightFlag"
                src="@/assets/image/knowledgeMall/bannerRight2.png"
                alt=""
              />
              <img
                v-show="!swiperRight && rightFlag"
                src="@/assets/image/knowledgeMall/bannerRight.png"
                alt=""
              />
            </div>
          </swiper>
        </template>
      </div>
      <img
        v-if="setmealList.length > 4"
        class="stepsfour"
        src="@/assets/image/knowledgeMall/setmealBackfour.png"
        alt=""
      />
      
      <div v-if="setmealList.length > 4" class="shopMessage">
        <p class="title">套餐价:</p>
        <div class="price">
          <p class="new">
            ￥<span>{{ managePrice(shopDetail.packagePrice * 1)[0] }}<span class="new_point">.{{ managePrice(shopDetail.packagePrice * 1)[1] }}</span></span>
          </p>
          <p class="old">原价: ￥{{ shopDetail.originalPrice.toFixed(2) }}</p>
        </div>
        <p class="warning">*此价格不包含已购买过的商品</p>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a
          class="tabsdiv"
          v-if="$route.query.packageId"
          href="javascript:;"
          @click="tabIndex = 1"
          :class="tabIndex == 1 ? 'tabsdiv a1' : ''"
        >
          <span>套餐介绍</span>
        </a>
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 套餐介绍 -->
        <div
          class="setMealMessage"
          v-if="tabIndex == 1"
          v-html="shopDetail.pcDetail"
        ></div>
      </div>
    </div>

    <!-- 透明盒子 -->
    <div class="opcityBox" @click="closeHidden" v-if="opcityBox"></div>

    <!-- 提交资质弹框 -->
    <SubmitQualification
      :courseId="courseId"
      :tipsText="tipsText"
      :qualificationvisible="qualificationvisible"
      @onCancel="cancelQuali"
      @onConfirm="confirmQuali"/>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
    <TipsModal :isPublicTipsShow="isPublicTipsShow" :maskClosable="true" ConfirmBtnText="我知道了" :tipsText="tipsText"  @setEvent="getEvent" />
  </div>
</template>

<script>
import TextOverflow from '@/components/TextOverflow.vue'
import SubmitQualification from '@/components/model/submitQualification.vue' // 提交资质
import Sidebar from "@/components/sidebar.vue";
// import forTree from '@/components/forTree/index.vue'
export default {
  // 可用组件的哈希表
  components: { TextOverflow, Sidebar, SubmitQualification },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      opcityBox: false,
      courseId: null,
      tipsText: '',
      qualificationvisible: false,
      couponFlag: false, // 优惠券弹框状态
      clientWidth: 0, // 视口宽度
      productList: [], // 相关图书教具
      isUnfold:false,
      imgSrc1: require("@/assets/image/temporary/Group4627.png"),
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 1,
      swiperLeft: false,
      swiperRight: false,
      rightFlag: true,
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      couponList: null, // 优惠券列表
      isPublicTipsShow:false,
      swiperOptions: {
        slidesPerView: 4,
        centeredSlides: false,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      // 图书教具轮播配置
      productSwiperOptions: {
        slidesPerView: "1.6",
        centeredSlides: false,
        // spaceBetween: 30,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-product-next",
          prevEl: ".swiper-product-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      // 包含课程列表
      courseList: [],
      // 套餐列表
      setmealList: [],
      shopDetail: null,
      showClassIndex: -1, //查看班级索引
    };
  },
  // 事件处理器
  methods: {
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    getEvent(){
      this.isPublicTipsShow = false
    },
    closeHidden(){
      let arr = this.courseList.filter(item=>{
        return item.hidden
      })
      arr[0].hidden = false;
    },
    // 判断该套餐是否可以购买
		isCanBuy() {
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      this.$ajax({
				url:'/hxclass-pc/course/packages/check-can-buy',
				method:'get',
				params:{
					packageId:this.shopDetail.packageId
				}
			}).then(res=>{
				if(res.code == 200 && res.success){
          if(this.courseList && this.courseList.length) {
            let status = false;
            this.courseList.map((item) => {
              if(item.haveClass == 1 && item.type == 1 && !item.isBuy){
                if(!item.openTimeId){
                  status = true;
                }
              }
            })
            // 套餐中课程是否都选择了班期
            if(status){
              this.$message.error("请选择课程班期")
            }else{
              this.goBuy();
            }
          }else{
            this.goBuy();
          }
        }else if(res.code == 1000){ // 需购买课程
          this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
          this.isPublicTipsShow = true;
        }else if(res.code == 1001){ // 需提交资质
          this.tipsText = '';
          this.courseId = res.data;
          this.qualificationvisible = true;
        }else if(res.code == 1002){ // 已提交资质待审核
          this.tipsText = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
          this.isPublicTipsShow = true;
        }else if(res.code == 1003){ // 审核被驳回
          this.tipsText = '您提交的相关资质证明已被驳回，请重新提交';
          this.courseId = res.data.courseId;
          this.qualificationvisible = true;
        }else if(res.code == 1004){ // 需购买课程且提交资质
          this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述资质，请提交您的资质信息，后台审核通过后方可报名。';
          this.courseId = res.data.courseId;
          this.qualificationvisible = true;
        }else if(res.code == 1005){ // "暂无可选择班级期数，无法进行购买"
					this.$message.error(res.message);
				}else if(res.code == 1006){// "“"+tCourse.getCourseName()+"”已购买过"
					this.$message.error(res.message);
				}else if(res.code == 1007){ // "课程”"+tCourse.getCourseName()+"“需要完成身份认证，无法购买"
					// this.show = true;
          this.$message.error(res.message);
				}else if(res.code == 1008){ // "您已购买本套餐中的“"+productArchives.getName()+"”无需重复购买。您可点击所选套餐中其他商品，单独下单购买。"
					this.$message.error(res.message);
				}
      })
    },
    // 套餐购买
    goBuy(){
      let productList = [];
			let list = [];
			this.shopDetail.productList.map(item=>{
				productList.push({
					productId:item.productId, // 商品id
					count:item.count, // 数量")
					openTimeId:item.openTimeId, // 班级id
					type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
					province:'', // 省
					city:'',// 市
					noGift:1, // 是否为赠品 0否1是t
				})
			})
      // 相关商品不加了-与pc做统一
      // console.log(this.productList)
      // this.productList.map(item=>{
      //   if(item.checked){
      //     productList.push({
      //       productId:item.productId, // 商品id
      //       count:item.count, // 数量")
      //       openTimeId:'', // 班级id
      //       type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
      //       province:'', // 省
      //       city:'',// 市
      //       noGift:1, // 是否为赠品 0否1是t
      //     })
      //   }
      // })
			list.push({
				productId:this.shopDetail.packageId, // 商品id
				count:1, // 数量")
				openTimeId:'', // 班级id
				type:9, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
				province:'', // 省
				city:'',// 市
				noGift:0, // 是否为赠品 0否1是t
				productList:productList
			})
			this.$store.commit('updatelist',list);
			this.$store.commit('updateType',1);
			this.$store.commit('updateShortInvoiceId',null);
			this.$store.commit('updateInvoiceId',null);
			this.$store.commit('updateAddress',null);
			this.$store.commit('updateCouponList',null);
			this.$router.push('/order/confirmOrder')
    },
    changeCount(e,size){
      e.count += size;
    },
    // 取消提交资质
    cancelQuali(){
      this.qualificationvisible = false;
    },
    // 提交资质
    confirmQuali(e){
      if(!e.imageUrl){
        return this.$message.error('请上传相关资质')
      }else if(!e.type){
        return this.$message.error('请选择资质类型')
      }
      this.$ajax({
        url:'/hxclass-pc/course/course-buy-audit/commit',
        method:'post',
        params:{
          certifTempId: e.type, // 资质模板id
          content: e.textarea, // 内容
          courseId: this.courseId, // 课程id
          code:e.code, //
          relevantFile: e.fileSrc, // 相关文件
          relevantFileName: e.fileName, // 相关文件名称
          relevantProof: e.imageUrl, // 相关证明
          userId: this.$store.state.userInfo.userId, // 用户id
        }
      }).then(res=>{
        if(res.code == 200){
          this.visible = true;
          this.fontText = res.message;
          this.qualificationvisible = false;
        }else{
          return this.$message.error(res.message);
        }
      })
    },
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },
    selectOpenTimeId(list,e){
      if(!e.currentNumber){
        return this.$message.error('该班级已报满，请选择其他班级')
      }
      this.$set(list, 'openTimeId', e.openTimeId)
      this.$set(list, 'openTimeName', e.className)
      // this.$set(list, 'hidden', false)
    },
    // 处理文字
    manageInfo(con) {
      let conNew = ''
      if (con && con.length < 94) {
        return con
      } else {
        conNew = con.substring(0,93)+'...'
        return conNew
      }
    },
    // 展开收起
    changeUnfold() {
      this.$set(this,'isUnfold',!this.isUnfold)
    },
    changeMask(e) {
      if (e == 1) {
        this.swiperLeft = true;
      } else if (e == 2) {
        this.swiperLeft = false;
      } else if (e == 3) {
        this.swiperRight = true;
      } else if (e == 4) {
        this.swiperRight = false;
      }
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "")
        );
      }
    },
    // 获取相关商品
    getProduceList(e){
      this.$ajax({
        url: "/hxclass-pc/course/course-relevant-product/by-course-product-code",
        method: "get",
        params: {
          courseProductCode: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = res.data.map((item) => {
            item["count"] = 1;
            return item;
          });
          if(this.productList.length){
            arr.map(item=>{
              let status = false;
              this.productList.map(ite=>{
                if(item.produceId == ite.productId){
                  status = true;
                }
              })
              if(!status){
                this.productList.push(item);
              }
            })
          }else{
            this.productList.push(...arr)
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取套餐详情
    getSetMealDetail() {
      this.$ajax({
        url:
          "/hxclass-pc/pc-packages/pc/" +
          this.$AES.decode_data(this.$route.query.packageId),
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(!res.data){
            return this.$message.error('该套餐已下架')
          }
          res.data.pcDetailPicture = JSON.parse(res.data.pcDetailPicture);
          this.shopDetail = res.data;
          if (res.data.productList) {
            this.setmealList = res.data.productList;
            // 获取相关商品列表
            res.data.productList.map(item=>{
              if(item.type == 1){
                this.getProduceList(item.code);
              }
            })
          }
          if (res.data.pcDetailPicture) {
            this.imgSrc = this.shopDetail.pcDetailPicture[0];
          }
          this.courseList = res.data.productList.filter((item) => {
            return item.type == 1;
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },

    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/shop/coupon-receive',
        method:'post',
        params:{
          couponId:e.couponId,
          receiver:this.$store.state.userInfo.userId,
          receiverTime:this.selectTime(),
          state:1
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/shop/coupon-list",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.packageId + ""), //	购物车商品 1,2,3,4
          type: e, // 1商品 2套餐
          productType: null,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 商品详情-查询课程期数
    getshopCourseDateList(e) {
      this.$ajax({
        url: "/hxclass-pc/course/pc/by-code",
        method: "get",
        params: {
          productCode: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 套餐课程-查询课程期数
    getCourseDateList(e, index) {
      // 查看班级
      this.showClassIndex = index
      let status = false;
      this.courseList.map(item=>{
        if(item.hidden){
          if(item.productId == e.productId){
            item.hidden = false;
            status = true;
          }else{
            item.hidden = false;
          }
        }
      })
      if(status){
        return 
      }
      if (e.hidden) {
        return this.$set(e, "hidden", false);
      }
      if (e.courseDateList) {
        return this.$set(e, "hidden", true);
      }
      this.$ajax({
        url: "/hxclass-pc/course/pc/by-code",
        method: "get",
        params: {
          productCode: e.code,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$set(e, "courseDateList", res.data);
          this.$set(e, "hidden", true);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    if (this.$route.query.packageId) {
      this.tabIndex = 1;
      this.getSetMealDetail();
      // 获取优惠券列表
      this.getCouponList(2);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        // console.log(document.body.clientWidth);
        that.clientWidth = window.screenWidth;
      })()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    "document.body.clientWidth"(newVal,oldVal){
      // console.log(newVal)
    },
    courseList: {
      handler:function () {
        let arr = this.courseList.filter(item=>{
          return item.hidden
        })
        if(arr.length){
          this.opcityBox = true;
        }else{
          this.opcityBox = false;
        }
      },
      deep:true
    }
  },
};
</script>

<style lang="less" scoped>
.opcityBox{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}
.setMealMessage {
  /deep/img {
    max-width: calc(100% - 80px) !important;
  }
}
@media screen and (max-width:16000px) {
  .custom-slick-arrow {
    cursor: pointer;
    margin: auto 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 12px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover{
    color: #ffffff;
    background: #15B7DD;
  }
}
// 图书教具走马灯
.carouselList {
  display: flex !important;
  justify-content: left;
  align-items: center;
  // height: 108px;
  @media screen and (max-width:16000px) {
    width: 502px !important;
    .custom-slick-arrow {
      width: 24px;
      height: 24px;
      font-size: 25px;
      color: #999999;
      background: #f5f5f5;
      border-radius: 12px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .custom-slick-arrow:before {
      display: none;
    }
    /deep/.swiper-slide:last-child{
      width: 310px !important;
    }
  }
  .item {
    width: 310px;
    // height: 108px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    border-right: 1px solid #ebebeb;
    margin-right: 10px;
    padding: 0 24px;
    display: flex;
    position: relative;
    .img {
      width: 60px;
      height: 60px;
    }
    .checked {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 4px;
      top: 26px;
      cursor: pointer;
    }
    .right {
      margin-left: 16px;
      flex: 1;
      .produceName {
        width: 182px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 21px;
      }
      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .price {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 21px;
        }
        .count {
          width: 80px;
          height: 24px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .item:first-child {
    margin-left: 38px !important;
  }
  .noprev:first-child{
    margin-left: 0 !important;
  }
  @media screen and (max-width:16000px) {
    .item:first-child {
      margin-left: 0 !important;
    }
  }
  .swiper-slide:last-child{
    .item {
      border: 0;
    }
  }
  .item:last-child {
    margin: 0;
  }
}
.ant-carousel {
  width: 707px;
  height: 108px;
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
    .item:last-child {
      border: 0;
    }
  }
  .custom-slick-arrow {
    width: 34px;
    height: 108px;
    font-size: 25px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  // .custom-slick-arrow:hover {
  //   opacity: 0.5;
  // }
  .slick-slide h3 {
    color: #fff;
  }
}
.setmealList {
  background-color: #ffffff;
  padding: 40px 0;
  .steps {
    display: block;
    margin: 10px auto;
    width: 820px;
    height: 10px;
  }
  .stepsfour {
    display: block;
    margin: 10px auto;
    width: 1025px;
    height: 10px;
  }
  .shopMessage {
    // @media screen and (max-width:16000px) {
    //   width: 664px;
    // }
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 25px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 25px;
      letter-spacing: 2px;
      margin-right: 25px;
    }
    .price {
      height: 58px;
      text-align: center;
      padding-top: 10px;
      .new {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #EC6C01;
        line-height: 18px;
        span {
          font-size: 31px;
          .new_point{
            font-size: 18px;
          }
        }
      }
      .old {
        margin-top: 2px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 19px;
        text-decoration: line-through;
      }
    }
    .warning {
      font-size: 16px;
      font-family: PingFangMedium;
      color: #999999;
      line-height: 19px;
      margin-left: 29px;
    }
    .button {
      cursor: pointer;
      background: #15b7dd;
      box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
      border-radius: 26px 26px 26px 26px;
      padding: 4px 36px;
      margin-left: 21px;
      font-size: 23px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 34px;
      .icon {
        color: #ffffff;
        font-size: 23px;
        margin-right: 6px;
      }
    }
  }
}
.setmealshopList {
  display: flex;
  justify-content: center;
  .industryitem {
    padding: 0 29px;
  }
  .industryitems{
    padding: 0 60px;
  }
}
.industryitem {
  position: relative;
  z-index: 3;
  .itemcontent {
    text-align: center;
    img {
      width: 257px;
      height: 224px;
      border-radius: 5px 5px 0px 0px;
    }
    .content {
      flex-direction: column;
      width: 257px;
      height: 114px;
      margin: 0 auto;
      text-align: left;
      padding: 12px 10px 21px;
      transition: all 0.5s;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 1px rgba(93, 131, 167, 0.1);
      cursor: pointer;
      .name {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 28px;
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 7px;
        height: 14px;
        .label {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #1e97b4;
          // line-height: 21px;
        }
        .oldPrice {
          font-size: 14px;
          font-family: PingFangMedium;
          text-decoration: line-through;
          color: #999999;
          line-height: 21px;
          margin-right: 9px;
        }
        .navs {
          font-size: 12px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 18px;
          border-radius: 4px;
          border: 1px solid #EC6C01;
          padding: 2px 8px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
      .newPrice {
        font-size: 12px;
        font-family: PingFangMedium;
        // font-weight: bold;
        color: #EC6C01;
        line-height: 18px;
        margin-top: 11px;
        span {
          font-size: 18px;
          .new_point{
            font-size: 12px;
          }
        }
      }
    }
    .setmealcontent {
      width: 257px;
      height: 114px;
      margin: 0 auto;
      text-align: left;
      padding: 12px 10px 21px;
      transition: all 0.5s;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 1px rgba(93, 131, 167, 0.1);
      .name {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 28px;
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 7px;
        .label {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #1e97b4;
          line-height: 21px;
        }
        .oldPrice {
          font-size: 14px;
          font-family: PingFangMedium;
          text-decoration: line-through;
          color: #999999;
          line-height: 21px;
          margin-right: 9px;
        }
        .navs {
          font-size: 12px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 18px;
          border-radius: 4px;
          border: 1px solid #EC6C01;
          padding: 2px 8px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
      .newPrice {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #EC6C01;
        line-height: 18px;
        margin-top: 11px;
        span {
          font-size: 18px;
          .new_point{
            font-size: 12px;
          }
        }
      }
    }
  }
  .warning {
    font-size: 16px;
    font-family: PingFangMedium;
    color: #999999;
    line-height: 19px;
  }
  .connector {
    position: absolute;
    right: -12.5px;
    top: 109px;
    .icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #15b7dd;
    }
  }
}
.rightArrow {
  position: absolute;
  left: -12.5px;
  top: 109px;
  width: 25px;
  height: 25px;
}
.buyBox {
  text-align: center;
  width: 257px;
  padding-top: 74px;
  .title {
    font-size: 25px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 41px;
  }
  .price {
    height: 58px;
    text-align: center;
    .new {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #EC6C01;
      line-height: 18px;
      span {
        font-size: 31px;
        .new_point{
          font-size: 18px;
        }
      }
    }
    .old {
      margin-top: 2px;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #666666;
      line-height: 19px;
      text-decoration: line-through;
    }
  }
  .button {
    cursor: pointer;
    background: #15b7dd;
    box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
    border-radius: 26px 26px 26px 26px;
    padding: 4px 36px;
    font-size: 23px;
    font-family: PingFangMedium;
    color: #ffffff;
    line-height: 34px;
    .icon {
      color: #ffffff;
      font-size: 23px;
      margin-right: 6px;
    }
  }
}
::v-deep.swiper-container {
  // margin: 0;
  width: 100%;
}

::v-deep.swiper-button-prev {
  position: absolute;
  left: 0px;
  top: 122px;
  width: 20px;
  img {
    width: 26px;
    height: 44px;
    position: relative;
  }
}
::v-deep.swiper-button-prev:after {
  display: none;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: -15px;
  top: 122px;
  img {
    width: 26px;
    height: 44px;
    position: relative;
  }
}
.industryitem {
  position: relative;
  .shopItem {
    text-align: center;
    .name {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
    .oldPrice {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .banner {
    background: #fafafa;
    border-radius: 4px;
    padding: 40px 24px;
    // display: flex;
    .item {
      display: flex;
      .icon {
        margin: 77px 30px 0;
      }
    }
    .shopItem {
      .img {
        width: 300px;
        height: 260px;
      }
      .shopintroduce {
        width: 300px;
        height: 133px;
        margin: 0 auto;
        border-radius: 0 0 5px 5px;
        box-sizing: border-box;
        border: 1px solid #15b7dd;
        padding: 12px 24px 20px;
        .name {
          font-size: 18px;
          font-family: PingFangMedium;
          color: #333333;
          line-height: 27px;
        }
        .introduce {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          .label {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #1e97b4;
            line-height: 21px;
          }
          .navs {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
          }
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .new {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            span {
              font-size: 20px;
            }
          }
          .old {
            font-size: 14px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
            text-decoration: line-through;
          }
        }
      }
    }
  }
  .moduletitle {
    padding: 10px 40px;
    margin-top: 40px;
    font-size: 24px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #15b7dd;
    line-height: 28px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    span {
      background-color: #15b7dd;
      height: 19px;
      width: 4px;
      border-radius: 7px;
      margin-right: 12px;
    }
  }
  .content {
    margin-top: 24px;
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        height: 496px;
        border-radius: 5px;
      }
      @media screen and (max-width:16000px) {
        .img {
          width: 426px;
          height: 426px;
        }
      }
      .imgList {
        display: flex;
        margin-top: 12px;
        .active {
          border: 1px solid #15b7dd;
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
        }
        @media screen and (max-width:16000px) {
          img {
            width: 52px;
            height: 52px;
          }
        }
        img:last-child {
          margin: 0;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      .name {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 600;
        color: #000000;
        line-height: 30px;
        span {
          font-weight: 400 !important;
          display: inline-block;
          width: 44px;
          height: 23px;
          text-align: center;
          background: rgba(21, 183, 221, 0.12);
          border-radius: 4px 4px 4px 4px;
          margin-right: 8px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #15b7dd;
          line-height: 23px;
        }
      }
      .describe {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #1e97b4;
        line-height: 21px;
      }
      .introduce {
        padding: 24px 16px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 24px;
        position: relative;
        .changeUnfold{
          font-size: 14px;
          position: absolute;
          bottom: 5px;
          right: 20px;
          cursor: pointer;
          color: #15b7dd;
        }
      }
      .classDate {
        margin-left: 16px;
        display: flex;
        .title {
          width: 71px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .classList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .classItem {
            cursor: pointer;
            width: 225px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 5px 0;
            text-align: center;
            .classname {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
            .time {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
          }
          .classItem:nth-child(3n) {
            margin-right: 0;
          }
        }
        .courseList {
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .courseItem {
            z-index: 5;
            position: relative;
            padding: 18px;
            padding-top: 4px;
            // border: 1px solid #EBEBEB;
            border-radius: 5px;
            // box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
            display: flex;
            width: 324px;
            margin-left: 16px;
            margin-bottom: 16px;
            @media screen and (max-width:16000px) {
              margin-left: 3px;
            }
            img {
              width: 60px;
              height: 60px;
            }
            .courseDateListWrap {
              background-color: #ffffff;
              position: absolute;
              top: 90px;
              left: 0;
              z-index: 5;
              box-shadow: 0px 0px 10px 3px rgba(93, 131, 167, 0.10);
              &::before {
                content: '';
                position: absolute;
                top: -9px;
                // right: 0;
                display: inline-block;
                width: 324px;
                height: 13px;
                background: #fff;
              }
            }
            .courseDateListWrapL::before {
              left: 0;
            }
            .courseDateListWrapR::before {
              right: 0;
            }
            .courseDateList {
              // width: 691px;
              min-width: 324px;
              max-width: 691px;
              border-radius: 0 10px 10px 10px;
              padding: 26px 16px 20px;
              display: flex;
              // flex-wrap: wrap;
              .courseDateItem {
                cursor: pointer;
                width: 213px;
                padding: 5px 17px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                border-radius: 4px;
                margin-right: 10px;
                border: 1px solid #ebebeb;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    // width: 85px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 28px;
                  }
                  .num {
                    span {
                      color: #15b7dd;
                    }
                  }
                }
              }
              .courseDateItem:nth-child(3n + 3) {
                margin: 0;
              }
              .courseDateItem:nth-child(n + 4) {
                margin-top: 10px;
              }
              .active {
                color: #ffffff;
                background: #15b7dd;
                .top {
                  .name {
                    color: #ffffff;
                  }
                  .num {
                    span {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
            .courseMessage {
              padding-left: 16px;
              .name {
                width: 208px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
              }
             
              @media screen and (max-width:16000px) {
                .name {
                  width: 169px;
                }
              }
              .findClassDate {
                cursor: pointer;
                // float: right;
                margin-top: 21px;
                // text-align: right;
                font-size: 12px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #15b7dd;
                line-height: 18px;
              }
            }
          }
          @media screen and (max-width:16000px) {
            .courseItem {
              width: 285px;
              height: 92px;
            }
          }
          .courseItemShadow {
            border: 1px solid #fff;
            box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
          }
        }
        .classList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .classItem {
            width: 225px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 5px 0;
            text-align: center;
            .classname {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
            .time {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
          }
          .classItem:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .buybutton {
        width: 142px;
        height: 46px;
        background: #15b7dd;
        border-radius: 23px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
        &:hover::before {
          left: 100%;
        }
        p {
          margin-left: 5px;
        }
      }
      .buybutton::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
        transition: all .5s;
      }
      .showAll {
        margin-bottom: 24px;
        margin-left: 71px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
        span {
          cursor: pointer;
        }
        .icon {
          margin-left: 4px;
        }
      }
      .shopMessage {
        margin-top: 20px;
        padding: 30px 16px;
        background: rgba(21, 183, 221, 0.05);
        position: relative;
        .studyNum {
          position: absolute;
          right: 27px;
          top: 30px;
          .num {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #15b7dd;
            line-height: 24px;
            text-align: right;
          }
          .font {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
          }
        }
        .title {
          width: 72px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .price {
          display: flex;
          align-items: center;
          .new {
            font-size: 16px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            margin-top: -5px;
            span {
              font-size: 28px;
            }
            .new_point {
              font-size: 16px;
            }
          }
          .newFree {
            font-size: 24px;
            margin-top: -9px;
          }
          .old {
            font-size: 16px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
            text-decoration: line-through;
          }
        }
        .navs {
          display: flex;
          align-items: center;
          margin-top: 24px;
          position: relative;
          .couponList{
            position: absolute;
            z-index: 6;
            top: 26px;
            left: 90px;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
            padding: 30px 20px 16px;
            .coupon{
              width: 322px;
              margin-bottom: 16px;
              padding-right: 15px;
              height: 80px;
              display: flex;
              align-items: center;
              background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
              background-size: 100% 100%;
              .couponLeft{
                padding-right: 20px;
                width: 93px;
                text-align: center;
                .price{
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  font-size: 16px;
                  font-family: PingFangRegular;
                  font-weight: 300;
                  color: #3A3330;
                  line-height: 37px;
                  span{
                    font-size: 30px;
                    line-height: 45px;
                    font-family: PingFangMedium;
                  }
                }
                .condition{
                  margin-top: -5px;
                  width: 80px;
                  font-size: 10px;
                  font-family: PingFangRegular;
                  font-weight: 400;
                  color: #3A3330;
                  line-height: 15px;
                  transform: scale(0.9);
                }
              }
              .couponRight{
                flex: 1;
                .couponName{
                  margin-left: -10px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 14px;
                }
                .useTime{
                  margin-left: -10px;
                  margin-top: 7px;
                  font-size: 12px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 8px;
                  transform: scale(0.9);
                }
                .scope{
                  margin-top: 8px;
                  font-size: 10px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #842C17;
                  line-height: 10px;
                }
              }
              .couponBtn{
                cursor: pointer;
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponStyle{
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponBtn,
              .couponStyle{
                text-align: center;
                width: 60px;
                height: 25px;
                border-radius: 2px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
              }
            }
            .coupon:last-child{
              margin-bottom: 0;
            }
            .geted{
              opacity: 0.5;
              // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
            }
          }
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
          .button {
            margin-left: 20px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 21px;
            padding-left: 20px;
            border-left: 1px dashed #999999;
            cursor: pointer;
          }
        }
        .giftList {
          display: flex;
          align-items: top;
          margin-top: 24px;
          .giftItem {
            display: flex;

            img {
              width: 40px;
              height: 40px;
            }
            .message {
              width: 68px;
              margin: 0 16px 0 8px;
              .giftName {
                font-size: 14px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 21px;
              }
              .count {
                margin-top: 5px;
                font-size: 12px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.tabs-box {
 margin-top: 46px;
  background-color: #fff;
  padding: 75px 40px 40px;
  margin-bottom: 50px;
  position: relative;
  .tabs {
    height: 56px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 0 20px;

    .tabsdiv {
      margin-right: 40px;
      // padding: 10px 20px;
      width: 182px;
      height: 64px;
      font-size: 20px;
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .a1 {
    
        background-image: url("../../assets/image/curriculum/20220607-134028.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        // transition: all 0.3s;
        //  font-size: 24px;
        color: @theme;

    }
    .a2 {
     
        background-image: url("../../assets/image/curriculum/20220506-144134.png");
        background-repeat: no-repeat;
        background-position: center;
        // background-size: 100%; 
        //  font-size: 24px;
        color: @theme;
        // transition: all 0.3s;
      }
  }
}
.contenttab {
  overflow: hidden;
  /deep/img {
    max-width: 100%;
  }
}
</style>
